import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'
import Messaging from '../messaging.js';
import ImageView from 'react-native-image-view';


import EonnButton from '../components/button';
import EonnButton2 from '../components/button2';
import EonnStars from '../components/stars';
import { mstyles } from '../constants/MainStyles';
import common from '../common';


const styles = StyleSheet.create({
  issueBox: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingTop: 10,
    paddingRight: 40,
    paddingBottom: 20,
    paddingLeft: 40,
    width: '100%',
  },
  issueBox2: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  issueImage: {
    width: 47,
    height: 47,
    marginBottom: 5,
  },
  issueText: {
    fontSize: 30,
    lineHeight: 32,
  },
  issueText2: {
    fontSize: 16,
    lineHeight: 18,
  },
  vehicleBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 15,
    paddingLeft: 40,
    borderTopColor: '#ceced2',
    borderTopWidth: 1,
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
  },
  vehicleText: {
    fontSize: 20,
    lineHeight: 22,
  },
  vehicleText2: {
    fontSize: 18,
    lineHeight: 20,
  },
  profileBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 40,
    paddingBottom: 15,
    paddingLeft: 40,
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
    marginBottom: 20,
  },
  profileText: {
    fontSize: 16,
    lineHeight: 18,
  },
  profileText2: {
    fontSize: 20,
    lineHeight: 22,
  },
  profileText3: {
    fontSize: 18,
    lineHeight: 20,
  },
  subtitle: {
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 16,
    marginTop: 0,
    marginRight: 18,
    marginBottom: 0,
    marginLeft: 12,
    maxWidth: 200,
  },
  
  uploadBoxes: {
    marginTop: 10,
    marginBottom: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadBox: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  uploadButton: {
  },
  uploadImage: {
    width: 80,
    height: 60,
    alignSelf: 'center',
  },
  uploadAdd: {
    width: 80,
    height: 60,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: "#909097",
  },
  uploadButtonText: {
    textAlign: 'center',
    fontSize: 40,
    lineHeight: 45,
    fontFamily: 'nunito',
    color: '#909097',
    fontWeight: 500,
  },
  uploadBoxShadow: {
    width: 80,
    height: 60,
    backgroundColor: '#e2e0e0',
    position: 'absolute',
    top: 4,
  }
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      err: "",
      imageModalVisible: false,
      images: [],
      selectedImage: 0,
    };
    console.log("consult: ",global.consult);

    let images = [];
    global.consult.media.map((item,index) => {
      //get width and height from uri
      Image.getSize(item, (width, height) => {
        images.push({
          source: { uri: item },
          width: width,
          height: height,
        });
      }, (error) => {
        console.log("error getting image size: ", error);
      });
    });
    this.state.images = images;

  }

  setModalVisible = (visible, index) => {
    console.log("setModalVisible: ", visible, index);
    this.setState({
      imageModalVisible: visible,
      selectedImage: index || 0,
    });
  }


  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>

        <ImageView
          images={this.state.images}
          imageIndex={this.state.selectedImage}
          isVisible={this.state.imageModalVisible}
          renderFooter={(currentImage) => (<View><Text>My footer</Text></View>)}
          onClose={() => this.setModalVisible(false)}
        />

        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }

        <View style={styles.issueBox}>
          <View style={styles.issueBox2}>
            <Image source={require('../assets/images/issue1.png')} style={styles.issueImage} />
            <Text style={[mstyles.mfont, styles.text, styles.issueText]}>
              {global.consult.desc}
            </Text>
          </View>
        </View>

        
        <View style={styles.vehicleBox}>
          <Image source={require('../assets/images/vehicle1.png')} style={styles.issueImage} />
          <View>
            <Text style={[mstyles.mfont, styles.text, styles.vehicleText]}>
              {global.consult.c_vehicle}
            </Text>
            <Text style={[mstyles.mfont, styles.text, styles.vehicleText2]}>
              {global.consult.c_miles} miles
            </Text>
          </View>
        </View>

        <View style={styles.profileBox}>
          <Image source={require('../assets/images/profile1.png')} style={styles.issueImage} />
          <View>
            <Text style={[mstyles.mfont, styles.text, styles.profileText]}>
              Requested by
            </Text>
            <Text style={[mstyles.mfont, styles.text, styles.profileText2]}>
              {global.consult.c_name}
            </Text>
            <Text style={[mstyles.mfont, styles.text, styles.profileText3]}>
              {common.timeDifference(global.consult.timeRequested)}
            </Text>
          </View>
        </View>

        <Text style={mstyles.text}>
          {global.consult.note}
        </Text>


        <View style={styles.uploadBoxes}>
          {global.consult.media.map((item,index) =>

          <View style={styles.uploadBox} key={index}>
            <View style={styles.uploadBoxShadow}></View>
            <TouchableHighlight
                style={styles.uploadButton}
                underlayColor={ 'rgba(0, 0, 0, 0.2)' }
                onPress={() => this.setModalVisible(true, index)}
            >
              <Image
                source={{ uri: item }}
                style={styles.uploadImage}
              />
            </TouchableHighlight>
          </View>

          )}
        </View>


        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
          <EonnButton
            navigationProps={this.props.navigation}
            screen={'T_Home'}
            white={true}
            style={{width:230}}
            >
              Skip Request
          </EonnButton>
          
          <EonnButton
            loading={this.state.loading}
            navigationProps={this.props.navigation}
            style={{width:330}}
            onPress={() => {
              this.setState({ loading: true });
              
              console.log("sending SMS to: ", global.consult.c_phone);
              Messaging.sendSMS(global.consult.c_phone, "Great news! " + global.user.name + " has placed a bid for your video consultation. Visit https://consult.autoteletech.com?cid=" + global.consult.id + " to review the mechanic's profile and accept or decline. Note that the bid will expire in seven minutes.")
              .then((response) => {
                console.log("sms response: ", response);
              });
        

              common.newAgoraToken(global.consult.id)
                .then(token => {
                  console.log("token2: ", token);
                  global.consult.token = token;
                  global.consult.channel = global.consult.id;
                  global.consult.status = "bid";
                  global.bid = {
                    token: global.consult.token,
                    channel: global.consult.channel,
                    t_uid: global.user.uid || "",
                    t_name: global.user.name || "",
                    t_email: global.user.email || "",
                    t_phone: global.user.phone || "",
                    t_rate: global.user.rate || "",
                    t_stars: global.user.stars || 4,
                    t_ratings: global.user.ratings || 0,
                    t_experience: global.user.experience || 8,
                    t_profilePicture: global.user.profilePicture || "",
                    t_about: global.user.about || "",
                    t_specializations: global.user.specializations || "",
                    t_completedConsults: global.user.completedConsults || "",
                    t_stripeId: global.user.stripeId || "",
                    t_review: "",
                    price: global.user.rate || "",
                    t_testing: global.user.testing || false,
                  };
                  return (global.bid);
                })
                .then(bid => FirebaseConsult.newBid(bid) )
                .then(() => common.storeUserData() )
                .then(() => this.props.navigation.reset({ index:0, routes:[{ name: 'T_Request2' }], }))
                .catch((error) => {
                  this.setState({ err: error.toString() });
                  this.setState({ loading: false });
                });

              /*
              FirebaseConsult.updateConsultInfo()
                .then(() => this.props.navigation.navigate("T_Consult1"))
                .catch((error) => {
                  this.setState({ err: error.toString() });
                  this.setState({ loading: false });
                });*/

            }}
            >
            Bid on request</EonnButton>
          </View>

      </ScrollView>
    );
  }
}