import 'react-native-gesture-handler';
import 'expo-dev-client';
import { StatusBar } from 'expo-status-bar';
import React, {Component} from 'react';
import { Platform, StyleSheet, View, Text, Alert } from 'react-native';
import * as Linking from 'expo-linking';

import { Asset } from 'expo-asset';
import * as Font from 'expo-font';

import AppLoading from 'expo-app-loading';
import AppNavigator from './navigation/AppNavigator';
import FirebaseSetup from './firebase/firebase_setup.js';
import FirebaseUsers from './firebase/firebase_users.js';
import FirebaseConsult from './firebase/firebase_consult.js';
import * as firebase_auth from 'firebase/auth';
import Messaging from './messaging.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import common from './common';

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      assetsLoaded: false,
      authLoaded: false,
    };
  }

  async parseInitialURL() {
    // Get the URL that opened the app
    const initialUrl = await Linking.getInitialURL();
    console.log("Initial URL:", initialUrl);
    
    if (initialUrl) {
      // Parse the URL to extract consultation ID
      const urlObj = new URL(initialUrl);
      const consultId = urlObj.searchParams.get('cid');
      
      if (consultId) {
        console.log("Found consultation ID in URL:", consultId);
        try {
          // Initialize Firebase first if needed
          if (!global.db) {
            await FirebaseSetup.init();
          }
          
          // Try to recover the consultation session
          const recovered = await FirebaseConsult.recoverConsultationSession(consultId);
          if (recovered) {
            console.log("Successfully recovered consultation session");
            // Set appropriate start screen based on consultation status
            if (global.consult.status === "requested") {
              global.C_StartScreen = 'C_Request1';
            } else if (global.consult.status === "in progress") {
              global.C_StartScreen = 'C_Consult1';
            } else if (global.consult.status === "ended") {
              global.C_StartScreen = 'C_Consult2';
            } else if (global.consult.status === "reportSent") {
              global.C_StartScreen = 'C_Consult3';
            }
            return true;
          }
        } catch (error) {
          console.log("Error recovering consultation:", error);
        }
      }
    }
    return false;
  }

  componentDidMount() {

    const params = new URLSearchParams(window.location.search);
    const side = params.get('side');
    
    global.techSide = false;
    if (side === "tech") {
      global.techSide = true;
    }
    
    // Try to recover session from URL first, then proceed with normal flow
    this.parseInitialURL().then(recoveredFromUrl => {
      if (recoveredFromUrl) {
        this.setState({ authLoaded: true });
        return;
      }
      
      // Continue with normal flow if no consultation was recovered from URL
      common.getUserData().then((data) => {
        console.log("data",data);
        if (data) {
          global.user = data.user;
          global.consult = data.consult;
          console.log("global.user",global.user);
          console.log("global.consult",global.consult);
        }
      })
      .then(() => FirebaseSetup.init())
      .then(loggedIn => {
        if (loggedIn) {

          global.T_StartScreen = 'T_Profile2';
          global.C_StartScreen = 'C_Profile1';

          if (global.consult.id) {
            global.C_StartScreen = 'C_Request1';
          }
          if (global.user.name) {
            global.T_StartScreen = 'T_Home';
          }
          if (global.consult.status === "bid") {
            global.T_StartScreen = 'T_Request2';
          }
          if (global.consult.status === "in progress") {
            global.T_StartScreen = 'T_Consult1';
            global.C_StartScreen = 'C_Consult1';
          }
          if (global.consult.status === "ended") {
            global.T_StartScreen = 'T_Consult2';
            global.C_StartScreen = 'C_Consult2';
          }
          if (global.consult.status === "reportSent") {
            global.C_StartScreen = 'C_Consult3';
          }

          this.setState({ 
            authLoaded: true,
          });
        } else {
          global.T_StartScreen = 'T_Intro1';
          global.C_StartScreen = 'C_Profile1';

          if (global.consult.id) {
            global.C_StartScreen = 'C_Request1';
          }
          if (global.consult.status === "in progress") {
            global.C_StartScreen = 'C_Consult1';
          }
          if (global.consult.status === "ended") {
            global.C_StartScreen = 'C_Consult2';
          }
          if (global.consult.status === "reportSent") {
            global.C_StartScreen = 'C_Consult3';
          }

          //global.C_StartScreen = global.consult.status === "in progress" ? 'C_Consult1' : global.consult.id ? "C_Request1" : "C_Profile1";
          this.setState({ 
            authLoaded: true
          });
        }
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
    });
    
    // Messaging.sendSMS("+1 817-905-0040", "test message")
    // .then((response) => {
    //   console.log("response");
    //   console.log(response);
    // });
    
    // Messaging.sendEmail("albius0@yahoo.com", "test subject", "test text", "<p class='testclass'>test html</p>")
    // .then((response) => {
    //   console.log("response: ", response);
    // });

  }

  render() {

      if (!this.state.assetsLoaded || !this.state.authLoaded ) {
        return (
          <AppLoading
            startAsync={loadResourcesAsync}
            onError={console.warn}
            onFinish={() => this.setState({ assetsLoaded: true })}
          />
        );
      } else {
        return (
          <View style={{
            flex: 1,
            height: '100%',
            width: '100%',
          }}>
            <AppNavigator/>
            <StatusBar style="dark" />
          </View>
        );
      }
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require('./assets/images/bg1.png'),
      require('./assets/images/logo.png'),
      require('./assets/images/button1.png'),
      require('./assets/images/portrait1.png'),
      require('./assets/images/specializations/ac1.png'),
      require('./assets/images/specializations/ac1b.png'),
      require('./assets/images/specializations/brakes1.png'),
      require('./assets/images/specializations/brakes1b.png'),
      require('./assets/images/specializations/fuel1.png'),
      require('./assets/images/specializations/fuel1b.png'),
      require('./assets/images/specializations/electronics1.png'),
      require('./assets/images/specializations/electronics1b.png'),
      require('./assets/images/specializations/electrical1.png'),
      require('./assets/images/specializations/electrical1b.png'),
      require('./assets/images/specializations/engine1.png'),
      require('./assets/images/specializations/engine1b.png'),
      require('./assets/images/specializations/steering1.png'),
      require('./assets/images/specializations/steering1b.png'),
      require('./assets/images/specializations/suspension1.png'),
      require('./assets/images/specializations/suspension1b.png'),
      require('./assets/images/specializations/transmission1.png'),
      require('./assets/images/specializations/transmission1b.png'),
      require('./assets/images/star2.png'),
      require('./assets/images/star2-over.png'),
      require('./assets/images/star2-empty.png'),
    ]),
    Font.loadAsync({
      'nunito': require('./assets/fonts/NunitoSans-Regular.ttf'),
    }),
    Font.loadAsync({
      'nunitolight': require('./assets/fonts/NunitoSans-Light.ttf'),
    }),
  ]);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
