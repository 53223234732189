import React from 'react';
import * as firebase from "firebase/app";
import * as firestore from 'firebase/firestore';
import * as firebase_auth from 'firebase/auth';
import common from '../common';

//import * as WebBrowser from 'expo-web-browser';
//import * as AuthSession from 'expo-auth-session';

export default class FirebaseConsult {

  static async updateConsultInfo() {
    try {
      console.log("updateConsultInfo: ", global.consult);
      await firestore.setDoc(firestore.doc(global.db, "consultations", global.consult.id), global.consult);
      console.log('Consult data updated');
      return true;
    } catch (error) {
      console.log('Error updating consult data:', error);
      throw error;
    }
  }

  static async cancelConsult() {
    console.log("cancelConsult");
    try {
      await firestore.updateDoc(firestore.doc(global.db, "consultations", global.consult.id), {
        status: "cancelled"
      });
      console.log('Consultation cancelled');
      return true;
    } catch (error) {
      console.log('Error cancelling consultation:', error);
      throw error;
    }
  }

  static async completeConsult() {
    console.log("completeConsult");
    try {
      await firestore.updateDoc(firestore.doc(global.db, "consultations", global.consult.id), {
        status: "completed"
      });
      console.log('Consultation completed');
      return true;
    } catch (error) {
      console.log('Error completing consultation:', error);
      throw error;
    }
  }

  //get an array of bids for global.consult.id
  static async getBids() {
    console.log("Getting bids");
    const bids = [];

    const consultdoc = firestore.doc(global.db, "consultations", global.consult.id);
    const querySnapshot = await firestore.getDoc(consultdoc);
    const data = querySnapshot.data();
    data?.bids && data.bids.forEach((bid) => {
      bids.push(bid);
    });

    return bids;
  }

  static async watchForBids(onBid) {
    console.log("watching for bids");
    const unsub = firestore.onSnapshot(firestore.doc(global.db, "consultations", global.consult.id), (doc) => {
      let data = doc.data();
      if (data) {
        if (data.status === "requested") {
          console.log("New Bid");
          onBid(data.bids);
        } else {
          console.log("cancelled");
          unsub();
          onDelete();
        }
      }
    });
  }

  static async newBid(bid) {
    try {
      await firestore.updateDoc(firestore.doc(global.db, "consultations", global.consult.id), {
        bids: firestore.arrayUnion(bid)
      });
      console.log('Bid added to consultation');
      return true;
    } catch (error) {
      console.log('Error adding bid to consultation:', error);
      throw error;
    }
  }

  static async cancelBid(bid) {
    console.log("cancelBid", bid);
    try {
      await firestore.updateDoc(firestore.doc(global.db, "consultations", global.consult.id), {
        bids: firestore.arrayRemove(bid)
      });
      console.log('Bid removed from consultation');
      return true;
    } catch (error) {
      console.log('Error removing bid from consultation:', error);
      throw error;
    }
  }

  static async getIssues(updateIssues) {
    console.log("Getting issues2");
    const issues = [];

    const consultationsCollection = firestore.collection(global.db, "consultations");
    const query = firestore.query(
      consultationsCollection,
      firestore.where("status", "==", "requested")
    );
  
    const querySnapshot = await firestore.getDocs(query);
    querySnapshot.forEach((doc) => {
      issues.push(doc.data());
    });

    updateIssues(issues);
  }

  static async watchForIssues(updateIssues) {
    console.log("watching for issues");
    const issues = [];

    const consultationsCollection = firestore.collection(global.db, "consultations");
    const query = firestore.query(
      consultationsCollection,
      firestore.where("status", "==", "requested")
    );

    const unsubscribe = firestore.onSnapshot(query, (snapshot) => {
     // snapshot.docChanges().forEach((change) => {
        /*if (change.type === "added") {
            console.log("New doc: ", change.doc.data());
            onIssue(change.doc.data());
        }*/
        //issues.push(change.doc.data());
      //});
      this.getIssues(updateIssues);
    });
    //updateIssues(issues);
  }

  /*
  static async watchForBidAccept(onAccept) {
    console.log("watching for bid acceptance");
    const unsub = firestore.onSnapshot(firestore.doc(global.db, "consultations", global.consult.id), (doc) => {
      if ( doc.data().acceptedBid ){
        onAccept(doc.data().acceptedBid == global.user.uid);
      }
    });
  }*/

  static async watchForBidAccept(onAccept, onDelete) {
    console.log("watching for bid acceptance");
    const docRef = firestore.doc(global.db, "consultations", global.consult.id);
    const unsub = firestore.onSnapshot(
      docRef,
      (doc) => {
        let data = doc.data();
        if (data) {
          console.log("doc exists");
          if (data.status === "requested") {
            if (data.acceptedBid) {
              console.log("acceptedBid");
              onAccept(data.acceptedBid == global.user.uid);
            }
          } else if (data.status === "in progress"){
            console.log("in progress");
            unsub();
          } else {
            console.log("cancelled");
            unsub();
            onDelete();
          }
        } else {
          unsub();
          onDelete();
        }
      },
      (error) => {
        console.log(error);
      });
  }

  static async watchForReport(onReport) {
    console.log("watching for report");
    const unsub = firestore.onSnapshot(firestore.doc(global.db, "consultations", global.consult.id), (doc) => {
      if ( doc.data().report ){
        onReport(doc.data());
        unsub();
      }
    });
  }

  static async getConsultationById(consultId) {
    console.log("Retrieving consultation by ID:", consultId);
    try {
      const docRef = firestore.doc(global.db, "consultations", consultId);
      const docSnap = await firestore.getDoc(docRef);
      
      if (docSnap.exists()) {
        console.log("Consultation found:", docSnap.data());
        return docSnap.data();
      } else {
        console.log("No consultation found with ID:", consultId);
        return null;
      }
    } catch (error) {
      console.log("Error retrieving consultation:", error);
      throw error;
    }
  }

  static async recoverConsultationSession(consultId) {
    console.log("Recovering consultation session:", consultId);
    try {
      const consultData = await this.getConsultationById(consultId);
      
      if (!consultData) {
        return false;
      }
      
      // Set up global state with consultation data
      global.consult = consultData;
      
      // Create minimal user object from consultation data
      global.user = {
        name: consultData.c_name,
        phone: consultData.c_phone,
        email: consultData.c_email,
        vehicle: {
          year: consultData.c_vehicle?.split(" ")[0],
          make: consultData.c_vehicle?.split(" ")[1],
          model: consultData.c_vehicle?.split(" ").slice(2).join(" "),
          mileage: consultData.c_miles
        }
      };
      
      // Store recovered data in AsyncStorage for future use
      await common.storeUserData();
      
      return true;
    } catch (error) {
      console.log("Error recovering consultation session:", error);
      throw error;
    }
  }
}
