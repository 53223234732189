import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnRateStars from '../components/rateStars';
import EonnTitle from '../components/title';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';
import FirebaseConsult from '../firebase/firebase_consult.js';
import FirebaseUsers from '../firebase/firebase_users.js';
import * as firestore from 'firebase/firestore';


const styles = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: '#ccc',
  },
  profileBox: {
    flex: 1,
    paddingLeft: 20,
  },
  profileTitle: {
    fontSize: 24,
    lineHeight: 26,
  },
  profileExp: {
    fontSize: 17,
    lineHeight: 19,
  },
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      err: "",
      loading: false
    };
    console.log("global.consult: ");
    console.log(global.consult);
  }


  handleRatingChange(rating) {
    global.consult.rating = rating;
    console.log("Rating: " + rating);
  }
  
  updateTechRating = async () => {
    try {
      // Get the technician's user info
      const techId = global.consult.acceptedBid || global.consult.t_uid;
      if (!techId) {
        throw new Error("Could not identify the technician for this consultation");
      }
      
      const techData = await FirebaseUsers.getUserInfo(techId);
      if (!techData) {
        throw new Error("Could not retrieve technician data");
      }

      // Calculate new average rating
      const currentRatings = techData.ratings || 0;
      const currentStars = techData.stars || 0;
      
      // Calculate the new values
      const newRatings = currentRatings + 1;
      const totalStarsValue = (currentStars * currentRatings) + (global.consult.rating || 5);
      const newStars = Math.round((totalStarsValue / newRatings) * 10) / 10; // Round to 1 decimal place
      
      // Update the tech's profile
      const updatedTechData = {
        ...techData,
        ratings: newRatings,
        stars: newStars,
        reviews: techData.reviews || [],
      };
      
      // Add this review to their reviews array if a review text was provided
      if (global.consult.t_review && global.consult.t_review.trim() !== "") {
        updatedTechData.reviews.push({
          consultId: global.consult.id,
          customerName: global.consult.c_name || "Anonymous",
          rating: global.consult.rating || 5,
          review: global.consult.t_review,
          date: new Date().toISOString()
        });
      }
      
      // Update technician in Firebase
      await firestore.setDoc(firestore.doc(global.db, "users", techId), updatedTechData);
      
      return true;
    } catch (error) {
      console.error("Error updating technician rating:", error);
      throw error;
    }
  }
  
  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Review Your Tech</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <View style={styles.topRow}>
          <Image source={{uri: global.consult.t_profilePicture}} style={styles.profileImage} />

          <View style={styles.profileBox}>
            <Text style={[mstyles.mfont,styles.profileTitle]}>{global.consult.t_name || "Auto Tech"}</Text>
            <Text style={[mstyles.mfont,styles.profileExp]}>Experience: {global.consult.t_experience || "?"} Years</Text>
          </View>
        </View>

        <Text style={[mstyles.text, { marginBottom:0, marginTop:20 }]}>How would you rate this tech?</Text>
        <EonnRateStars onRatingChange={(rating) => this.handleRatingChange(rating)} />

        <Text style={[mstyles.text, { marginBottom:0, marginTop:20 }]}>Write a review (optional)</Text>
        <EonnField
          onChange={value => Object.assign(global.consult,{ t_review:value }) }
          value={global.consult.t_review}
          lines={10}
          ></EonnField>


        <EonnButton
          navigationProps={this.props.navigation}
          loading={this.state.loading}
          onPress={() => {
            this.setState({ loading: true });
            global.consult.status = 'completed';
            
            // First update the tech's rating/review data
            this.updateTechRating()
              .then(() => common.storeUserData())
              .then(() => FirebaseConsult.updateConsultInfo())
              .then(() => {
                this.props.navigation.navigate(global.auth.currentUser ? 'C_Reports' : 'C_SignUp');
              })
              .catch((error) => {
                console.log("error: ", error);
                this.setState({ err: error.message, loading: false });
              });
          }}
        >
          Send Review
        </EonnButton>
      </ScrollView>
    );
  }
}