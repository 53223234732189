import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import EonnButton from '../components/button';
import EonnStars from '../components/stars';
import { mstyles } from '../constants/MainStyles';
import FirebaseUsers from '../firebase/firebase_users.js';


const styles = StyleSheet.create({
  topRow: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  profileBox: {
    flex: 1,
    paddingLeft: 20,
  },
  profileTitle: {
    fontSize: 24,
    lineHeight: 26,
  },
  profileExp: {
    fontSize: 17,
    lineHeight: 19,
  },
  rate1: {
    fontSize: 20,
    lineHeight: 22,
  },
  rate2: {
    fontSize: 24,
    lineHeight: 26,
  },
  rateDollar: {
    fontSize: 19,
  },
  about: {
    fontSize: 16,
    paddingVertical: 15,
    paddingHorizontal: 35,
  },
  subtitle: {
    fontSize: 20,
    marginTop: 20,
  },
  text: {
    fontSize: 16,
  }
});

export default class Screen extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      techData: null,
      loading: true,
    };
    
    // Fetch the latest tech data including ratings
    if (global.bid?.t_uid) {
      FirebaseUsers.getUserInfo(global.bid.t_uid)
        .then(techData => {
          if (techData) {
            this.setState({
              techData: techData,
              loading: false
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(error => {
          console.log("Error fetching tech data:", error);
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }
  
  render() {
    const { techData } = this.state;
    const stars = techData?.stars || global.bid.t_stars || 4;
    const ratings = techData?.ratings || global.bid.t_ratings || 0;
    
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <View style={styles.topRow}>
          {/*<Image source={require('../assets/images/portrait1.png')} style={styles.profileImage} />*/}
          <Image source={{uri: global.bid.t_profilePicture}} style={styles.profileImage} />
          <View style={styles.profileBox}>
            <Text style={[mstyles.mfont,styles.profileTitle]}>{global.bid.t_name}</Text>
            <Text style={[mstyles.mfont,styles.profileExp]}>Experience: {global.bid.t_experience} Years</Text>
            <EonnStars stars={Math.round(stars)}>{ratings}</EonnStars>
          </View>
        </View>
        <Text style={[mstyles.mfont,styles.rate1]}>Consultation Rate</Text>
        <Text style={[mstyles.mfont,styles.rate2]}><Text style={styles.rateDollar}>$</Text>{global.bid.price}</Text>
        <Text style={[mstyles.mfont,styles.about]}>{global.bid.t_about}</Text>
        <Text style={[mstyles.mfont,styles.subtitle]}>Vehicle Specializations</Text>
        {global.bid.t_specializations?.map((specialization,i) =>
          <Text style={[mstyles.mfont,styles.text]} key={i}>{specialization}</Text>
        )}
        <Text style={[mstyles.mfont,styles.subtitle]}>Completed Virtual Consultations</Text>
        <Text style={[mstyles.mfont,styles.text]}>{global.bid.t_completedConsults}</Text>

        <EonnButton style={{marginBottom:0}} navigationProps={this.props.navigation} screen={'C_Request3_Stripe'}>Accept</EonnButton>
        <EonnButton navigationProps={this.props.navigation} screen={'C_Request1'} white={true}>Back</EonnButton>

      </ScrollView>
    );
  }
}