import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Alert,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  Button,
  View,
} from 'react-native';

import FirebaseConsult from '../firebase/firebase_consult.js'

import EonnButton from '../components/button';
import EonnButton2 from '../components/button2';
import EonnTitle from '../components/title';
import EonnEllipsis from '../components/ellipsis';
import { mstyles } from '../constants/MainStyles';
import common from '../common';


const styles = StyleSheet.create({
  waitingBox: {
    flexDirection:"row",
    maxWidth:200,
    justifyContent:'center',
    alignItems:'center',
    marginTop: 25,
  },
  waitingText: {
    fontSize:20,
    textAlign:'center'
  },
  issueButton: {
    borderBottomColor: '#ceced2',
    borderBottomWidth: 1,
    width: '100%',
  },
  issueButton2: {
    flexDirection: 'row',
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    justifyContent:'space-between',
    alignItems:'center',
  },
  issueName: {
    fontSize: 16,
    lineHeight: 18,
    marginBottom: 4,
  },
  issueTitle: {
    fontSize: 20,
    lineHeight: 21,
  },
  issueVehicle: {
    fontSize: 17,
    lineHeight: 19,
    marginTop: 3,
  },
  profileArrow: {
    marginRight: 5,
    marginLeft: 10,
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      err: "",
    };
  }

  componentDidMount(){
    FirebaseConsult.getIssues( issues => this.setState({issues:issues}) );
    FirebaseConsult.watchForIssues( issues => this.setState({issues:issues}) );
  }

  updateIssues = (issues) => {
    //console.log("consult");
    //console.log(consult);
    /*this.setState(prevState => ({
      issues: [...prevState.issues, consult]
    }))*/
    this.setState({issues:issues});
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        {/*}
        <EonnButton
          onPress={() => {
            global.consult = {
              id: common.randomID(),
              c_name: "Jeremy Hamilton",
              c_vehicle: "2016 Honda Civic",
              c_miles: "87,000",
              channel: "test1",
              desc: "Engine fails to start",
              timeRequested: new Date().toISOString(),
              note: "My car keeps dying on me while driving and when it dies, all the lights on my dash comes up and it won't start after several cranks. I need to know what could be wrong, and estimated cost to fix it.",
              token: "007eJxTYHCTKFi94cjdkHp5rc8HU2z3OW9etIP/qPPx780HvV8KsN5VYDA3T0tOMzAztjRITDZJTjSzME1LTDZPNEoyNk8zTrQwPPmUP6UhkJHB5Uo4EyMDBIL4rAwlqcUlhgwMAMr7IRo=",
            };
            global.user.uid = "test";
            FirebaseConsult.updateConsultInfo();
          }}
          >
        Test2</EonnButton>*/}
        
        {!global.user.stripeId &&
           <EonnButton2
              navigationProps={this.props.navigation}
              image={require('../assets/images/button1.png')}
              style={{ marginBottom:40, marginTop:40 }}
              paddingOffset={10}
              lines={[
                {text: 'COMPLETE PROFILE', size: 23},
                {text: 'TO ACCEPT CONSULT REQUESTS', size: 14},
              ]}
              screen={'T_Profile3'}
            ></EonnButton2>
        }

        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }

        <EonnTitle style={{marginBottom:0, fontSize:21, lineHeight:28 }}>Available Customer Requests</EonnTitle>
        {this.state.issues[0] 
          ? this.state.issues
              .sort((a, b) => new Date(b.timeRequested) - new Date(a.timeRequested))
              .map((issue, i) =>
              <TouchableHighlight
                key={i}
                onPress={() => {
                  global.consult = issue;
                  if (global.user.stripeId){
                    this.props.navigation.navigate('T_Request1');
                  } else {
                    //Alert.alert('Error', 'Plese complete your profile before accepting virtual consultations.');
                    this.setState({ err: "Plese complete your profile before accepting virtual consultations." });

                  }
                }}
                style={styles.issueButton}
                underlayColor={'#e6e6eb'}
              >
                <View style={styles.issueButton2}>
                  <View style={{flex:1}}>
                    <Text style={[mstyles.mfont,styles.issueName]}>{( common.timeDifference(issue.timeRequested) )}</Text>
                    <Text style={[mstyles.mfont,styles.issueTitle]}>{issue.desc}</Text>
                    <Text style={[mstyles.mfont,styles.issueVehicle]}>{issue.c_vehicle}</Text>
                  </View>
                  <Image source={require('../assets/images/arrow1.png')} style={styles.profileArrow} />
                </View>
              </TouchableHighlight>
            )
          : <View style={styles.waitingBox}>
              <Text style={[mstyles.mfont,styles.waitingText]}>Waiting for requests
              <EonnEllipsis style={[mstyles.mfont,styles.waitingText]}/></Text>
            </View>
        }
      </ScrollView>
    );
  }
}