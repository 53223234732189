import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
} from 'react-native';

import FirebaseUsers from '../firebase/firebase_users.js'

import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import { mstyles } from '../constants/MainStyles';
import common from '../common';

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
    };
    console.log(global.user);
    
  }

  render() {
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Complete your profile</EonnTitle>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <EonnField
          onChange={value => Object.assign(global.user,{ name:value }) }
          showRequired={this.state.showRequired}
          value={global.user.name}
          >Your Name</EonnField>
        {!global.user.uid && <EonnField
          onChange={value => Object.assign(global.user,{ email:value }) }
          showRequired={this.state.showRequired}
          value={global.user.email}
          >Your Email</EonnField> }
        <EonnField
          onChange={value => Object.assign(global.user,{ phone:value }) }
          showRequired={this.state.showRequired}
          value={global.user.phone}
          >Your Phone Number</EonnField>
        <Text style={[mstyles.text,{fontSize:15,maxWidth:380}]}>Enter your number to get notified when an auto mechanic is ready to help!</Text>
      
        <EonnButton
          loading={this.state.loading}
          navigationProps={this.props.navigation}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.name,
            global.user.phone
          ]}
          onPress={() => {
              const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
              if (emailRegex.test(global.user.email)) {
                common.newConsult()
                .then(() => this.props.navigation.navigate("C_Request0"));
              } else { 
                this.setState({ err: "Invalid email" });
              }
              
          }}
          >
          Next</EonnButton>
      </ScrollView>
    );
  }
}