import React, { Component } from 'react';
import { Text, StyleSheet, Image, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

export default function EonnTitle(props) {
  
    const styles = StyleSheet.create({
      container: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 30,
      },
      grad1: {
        width: 12,
      },
      grad2: {
        flex:1,
        flexDirection: 'row',
        alignItems: 'center',
      },
      lines: {
        marginTop: 23,
        marginBottom: 20,
        marginLeft: 15,
        marginRight: 30,
        width: '100%',
      },
      text: {
        textAlign: 'left',
        fontFamily: 'nunito',
        color:'#ffffff',
        maxWidth: '90%',
      },
      text2: {
        marginTop: 18,
        marginBottom: 20,
        marginLeft: 30,
        marginRight: 30,
        fontSize: 25,
        lineHeight: 32,
      },
      checkmark: {
        marginLeft:15
      }
    });


    //const textStyles = { fontSize:props.style?.fontSize, lineHeight:props.style?.lineHeight };


    const textStyles = {};
    if (props.style?.fontSize){
      ({ fontSize: textStyles.fontSize } = props.style);
    }
    if (props.style?.lineHeight){
      ({ lineHeight: textStyles.lineHeight } = props.style);
    }


    return (
      <View style={[styles.container,props.style]}>
        <LinearGradient
          colors={['#ed3d2a', '#d13025']}
          style={styles.grad1}
          start={[0.5, 0]}
          end={[0.5, 1]}
        ></LinearGradient>
        <LinearGradient
          colors={['#202025', '#17171a']}
          style={styles.grad2}
          start={[0, 0.5]}
          end={[1, 0.5]}
        >
          {props.checkmark && <Image
            source={require('../assets/images/checkmark1.png')}
            style={styles.checkmark}
          />}
          
          {props.lines
            ? <View style={styles.lines}>
                {props.lines.map((item,index) =>
                  <Text
                    key={index}
                    style={[styles.text, {
                      fontSize:item.size ? item.size : 20,
                      lineHeight:item.size ? item.size+2 : 22,
                      marginTop: item.marginTop ? item.marginTop : -2,
                      }, textStyles ]}>
                    {item.text ? item.text : null}</Text>
                )}
              </View>
            : <Text style={[styles.text,styles.text2,textStyles]}>{props.children}</Text>
          }
        </LinearGradient>
      </View>
    );
}

