import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';

import { mstyles } from '../constants/MainStyles';

export default class EonnStars extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    // Ensure stars is a valid number and rounds to nearest whole number
    const starCount = Math.round(Number(this.props.stars) || 0);
    
    return (
      <View style={{flexDirection:'row', marginTop:3 }}>
        {[...Array(starCount)].map((e, i) => 
          <Image key={i} source={require('../assets/images/star1.png')} style={{width:14, height:14}} />
        )}
        <Text style={[mstyles.mfont,{fontSize:13, lineHeight:17, marginLeft:3}]}>{this.props.children}</Text>
      </View>
    );
  }
}