import React, { Component, useState, useEffect } from 'react';

import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
  Alert,
} from 'react-native';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';


import EonnTitle from '../components/title';
import EonnButton from '../components/button';
import { mstyles } from '../constants/MainStyles';
import FirebaseConsult from '../firebase/firebase_consult.js'
import common from '../common';
import Messaging from '../messaging.js';

console.log(global.user);
console.log(global.bid);

const CARD_ELEMENT_OPTIONS = {
  style: {
    width: '600px',
    border: "1px solid #000000",
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4"
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

//const stripePromise = global.bid?.t_testing ? loadStripe('pk_test_O4v4IHKU1kaPxNUesvNGjgBr00eRcPAM3U') : loadStripe('pk_live_nppmiwFmnP49W0fA2wK1Qf9U00kKxz6VRO');
//const stripePromise = loadStripe('pk_test_O4v4IHKU1kaPxNUesvNGjgBr00eRcPAM3U');

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [err, setErr] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  let paymentMethodId = null;

  useEffect(() => {

    fetch('https://us-central1-eonn-teletech.cloudfunctions.net/createStripeCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: global.user.email,
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log("createStripeCustomer response: ", data);
      setCustomerId(data.customerID);
      console.log("customerId: ", customerId);
      return fetch('https://us-central1-eonn-teletech.cloudfunctions.net/SetupIntent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: global.user.email,
          customerID: data.customerID,
          currency: 'usd',
        }),
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log("SetupIntent response: ", data);
      setClientSecret(data.clientSecret);
      console.log("clientSecret: ", clientSecret);
      setLoading(false);
    })
    .catch(error => {
      console.error('Error:', error);
      setErr("An error has occurred. Please reload and try again.");
      setLoading(false);
    });
  }, []);

  const handleSubmit = async (event) => {

    setLoading(true);

    console.log("customerId: ", customerId);

    event.preventDefault();

    if (!termsChecked) {
      setErr("Please accept the terms and conditions and try again.");
      setLoading(false);
      return;
    }

    if (!stripe || !elements || !clientSecret || !termsChecked) { 
      setErr("The payment couldn't be processed. Please try again.");
      console.log("stripe, elements, clientSecret, or termsChecked is null");
      setLoading(false);
      return;
    }
  
    const cardElement = elements.getElement(CardElement);

    console.log("cardElement: ", cardElement);
  
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (setupIntent?.status === 'succeeded') {
      console.log('[setupIntent]', setupIntent);
      paymentMethodId = setupIntent.payment_method;


      global.consult = {
        ...global.consult,
        ...global.bid,
        paymentMethodId: paymentMethodId,
        customerId: customerId,
        acceptedBid: global.bid.t_uid,
      };

      const paymentIntentId = await common.handlePayment(global.bid.price);

      if (!paymentIntentId) {
        setErr("The payment couldn't be processed. Please try again.");
        console.log("paymentIntentId is null");
        setLoading(false);
        return;
      }

      FirebaseConsult.updateConsultInfo()
      .then (() => {
        common.storeUserData();
        console.log("sending SMS to: ", bid.t_phone);
        Messaging.sendSMS(bid.t_phone, "Your bid has been accepted for the issue: " + global.consult.desc + ". Visit https://consult.autoteletech.com to begin your virtual consultation.")
        .then((response) => {
          console.log("sms response: ", response);
        });
        props.navigation.navigate('C_Consult1');
      } )
      .catch(error => {
        console.log(error);
        setErr("The payment couldn't be processed. Please try again.");
        setLoading(false);
      });

    } else if (error) {
      setErr("The payment couldn't be processed. Please try again.");
      console.log('[error]', error);
      setLoading(false);
    } else {
      setErr("The payment couldn't be processed. Please try again.");
      console.log("setupIntent status is not succeeded");
      setLoading(false);
    }
  };


  // const handleFinalPayment = async (tipAmount) => {
  //   console.log("handleFinalPayment");
  //   console.log("paymentMethodId: ", paymentMethodId);
  //   console.log("customerId: ", customerId);

  //   const finalAmount = global.bid.t_rate + tipAmount;
  
  //   const response = await fetch('https://us-central1-eonn-teletech.cloudfunctions.net/createFinalPaymentIntent', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       amount: finalAmount,
  //       customerID: customerId,
  //       paymentMethodId: paymentMethodId,
  //       connectedAccountId: global.bid.t_stripeId,
  //       email: global.user.email,
  //     }),
  //   });

  //   if (!response.ok) {
  //     console.log("response not ok");
  //     return;
  //   }

  //   const { paymentIntentId, error } = await response.json();

  //   if (error) {
  //     setErr("The payment couldn't be processed. Please try again.");
  //     console.log('[error]', error);
  //     return;
  //   } else if (paymentIntentId) {
  //     console.log('[paymentIntentId]', paymentIntentId);
  //     return paymentIntentId;
  //   }

  // };
  

  return (

    <View style={{width:"100%", textAlign:"center", justifyContent:"center", alignItems:"center"}}>
      {err
        ? <Text style={mstyles.err}>{err}</Text>
        : null
      }
      <form onSubmit={handleSubmit} style={{width:"80%", textAlign:"center"}}>
        <div style={{
          border: "1px solid rgb(225, 225, 225)",
          padding: "10px",
          maxWidth: "450px",
          margin: "0 auto",
        }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        <View style={{marginTop:20, textAlign:"center"}}>
          <label>
            <input
              type="checkbox"
              checked={termsChecked}
              onChange={() => setTermsChecked(!termsChecked)}
            />
            <span style={{ marginLeft:"10px" }}>I accept the <a href="https://www.autoteletech.com/privacy.html" target="_blank">terms and conditions</a></span>
          </label>
        </View>

        {loading === false
        ? termsChecked
          ? <button type="submit" disabled={!stripe || !termsChecked} style={{
              backgroundColor: '#d22f25',
              border: 'none',
              color: 'white',
              padding: '15px 60px',
              textAlign: 'center',
              textDecoration: 'none',
              display: 'inline-block',
              fontFamily: 'nunito',
              fontSize: '20px',
              fontWeight: '500',
              margin: '30px auto 0',
              cursor: 'pointer',
              width: '380px',
              boxShadow: '0px 4px #e2e0e0',
              borderRadius: '5px',
            }}>
              Place Order
            </button>
          : <button type="submit" disabled={true} style={{
              backgroundColor: '#808080',
              border: 'none',
              color: 'white',
              padding: '15px 60px',
              textAlign: 'center',
              textDecoration: 'none',
              display: 'inline-block',
              fontFamily: 'nunito',
              fontSize: '20px',
              fontWeight: '500',
              margin: '30px auto 0',
              cursor: 'pointer',
              width: '380px',
              boxShadow: '0px 4px #e2e0e0',
              borderRadius: '5px',
            }}>
              Place Order
            </button>
        : <button type="submit" disabled={true} style={{
          backgroundColor: '#d22f25',
          border: 'none',
          color: 'white',
          padding: '15px 60px',
          textAlign: 'center',
          textDecoration: 'none',
          display: 'inline-block',
          fontFamily: 'nunito',
          fontSize: '20px',
          fontWeight: '500',
          margin: '30px auto 0',
          cursor: 'pointer',
          width: '380px',
          boxShadow: '0px 4px #e2e0e0',
          borderRadius: '5px',
        }}>
          Loading...
        </button>
        }
      </form>
    </View>
  );
};


export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      err: "",
    };
  }

  render() {
    const stripePromise = global.bid?.t_testing ? loadStripe('pk_test_O4v4IHKU1kaPxNUesvNGjgBr00eRcPAM3U') : loadStripe('pk_live_nppmiwFmnP49W0fA2wK1Qf9U00kKxz6VRO');
    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
        <EonnTitle>Checkout</EonnTitle>
        <Text style={mstyles.subtitle}>
          Total amount due: ${global.bid?.price || "0"}.00
        </Text>
        <Elements stripe={stripePromise}>
          <CheckoutForm {...this.props} />
        </Elements>
        <EonnButton navigationProps={this.props.navigation} screen={'C_Request1'} white={true}>Back</EonnButton>

      </ScrollView>
    );
  }
}
