import React, { Component } from 'react';
import {
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  Button,
  View,
  BackHandler,
} from 'react-native';

import EonnButton from '../components/button';
import EonnDropdown from '../components/dropdown';
import EonnLink from '../components/link';
import EonnField from '../components/field';
import EonnTitle from '../components/title';
import { mstyles } from '../constants/MainStyles';

const styles = StyleSheet.create({
  logo: {
    width: '100%',
    height: null,
    aspectRatio: 2.27,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  title: {
    fontSize: 40,
    lineHeight: 35,
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 10,
    maxWidth: '80vw'
  },
  sContent: {
    paddingTop: 0,
    paddingBottom: 30,
    flexDirection: 'column',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
});

export default class Screen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showRequired: false,
      err: "",
    };
    console.log(global.user);
  }

  render() {

    const years = ["Other"];
    const currentYear = new Date().getFullYear();
    for (let i = 1995; i <= currentYear + 1; i++) {
      years.push(i);
    }
    years.reverse();

    const makes = ['Other', 'Acura', 'Alfa Romeo', 'Audi', 'Bentley', 'BMW', 'Bugatti', 'Buick', 'Cadillac', 'Chevrolet', 'Chrysler', 'Dodge', 'Ferrari', 'Fiat', 'Ford', 'Genesis', 'GMC', 'Honda', 'Hyundai', 'Infiniti', 'Jaguar', 'Jeep', 'Kia', 'Lamborghini', 'Land Rover', 'Lexus', 'Lincoln', 'Lotus', 'Maserati', 'Mazda', 'Mercedes-Benz', 'Mini', 'Mitsubishi', 'Nissan', 'Porsche', 'Ram', 'Rolls-Royce', 'Subaru', 'Tesla', 'Toyota', 'Volkswagen', 'Volvo'];

    return (
      <ScrollView style={mstyles.sContainer} contentContainerStyle={mstyles.sContent}>
      <ImageBackground
        source={require('../assets/images/bg4.jpg')}
        style={styles.sContent}
      >
        <Image
          source={require('../assets/images/intro-logo.png')}
          style={styles.logo}
        />
        <Text style={[mstyles.mfont,styles.title]}>Talk to an auto tech</Text>
        {this.state.err
          ? <Text style={mstyles.err}>{this.state.err}</Text>
          : null
        }
        <Text style={[mstyles.text, mstyles.center]}>Expert auto advice is just a few {Platform.OS === 'web' ? 'clicks' : 'taps'} away! To start, please enter the year, make, and model of your vehicle: </Text>
        <EonnDropdown
          items={years}
          onChange={value => Object.assign(global.user, { vehicle: { ...global.user.vehicle, year: value } })}
          showRequired={this.state.showRequired}
          value={global.user.vehicle?.year}
          label="Vehicle Year"
          placeholder="Select a year"
        />
        <EonnDropdown
          items={makes}
          onChange={value => Object.assign(global.user, { vehicle: { ...global.user.vehicle, make: value } })}
          showRequired={this.state.showRequired}
          value={global.user.vehicle?.make}
          label="Vehicle Make"
          placeholder="Select a make"
        />
        <EonnField
          onChange={value => Object.assign(global.user, { vehicle:{ ...global.user.vehicle, model: value } }) }
          showRequired={this.state.showRequired}
          value={global.user.vehicle?.model}
          >Vehicle Model</EonnField>
        <EonnField
          onChange={value => Object.assign(global.user, { vehicle: { ...global.user.vehicle, mileage: value } })}
          showRequired={this.state.showRequired}
          value={global.user.vehicle?.mileage}
          inputMode={"numeric"}
          keyboardType={"numeric"}
        >Vehicle Mileage</EonnField>
        <EonnButton
          onPress={() => {
            const { mileage } = global.user.vehicle;
            const mileageWithoutCommas = mileage.replace(/,/g, ''); // Remove commas from mileage
            if (isNaN(mileageWithoutCommas)) {
              this.setState({ err: "Mileage must be a number" });
            } else {
              this.setState({ err: "" });
              this.props.navigation.navigate("C_Issue1");
            }
          }}
          showRequired={() => this.setState({ showRequired: true })}
          required={() => [
            global.user.vehicle?.year,
            global.user.vehicle?.make,
            global.user.vehicle?.model
          ]}
        >
          Next
        </EonnButton>
        </ImageBackground>
      </ScrollView>
    );
  }
}